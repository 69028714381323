<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('log_account.head') }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('log_account.head_list') }} </v-card-title>
        <v-card-actions class="pb-0 d-flex align-start">
          <v-row no-gutters>
            <span>
              <date-picker-input :label="$t('log_account.date_from')" :date="dateFrom" @setValue="dateFrom = $event" />
            </span>
            <span>
              <date-picker-input :label="$t('log_account.date_to')" :date="dateTo" @setValue="dateTo = $event" />
            </span>
            <v-btn color="primary" class="" @click="findByDate"> {{ $t('search') }} </v-btn>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-spacer />
          <span class="px-3 mt-5">{{ $t('search') }}:</span>
          <div style="width: 150px" class="mt-5">
            <v-text-field v-debounce:500ms="searchKeyword" :date="searchData" outlined dense hide-details />
          </div>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :items="members"
            :no-data-text="$t('not_found')" :no-results-text="$t('not_found')" :options.sync="pagination"
            :server-items-length="pagination.totalItems" :items-per-page="pagination.rowsPerPage"
            :page.sync="pagination.page" :loading="loading" :search="search" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }">
            <template v-slot:[`item.created_at`]="{ item }">
              <span>{{ formatDate(item.created_at) }}</span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn color="warning" class="white--text text-capitalize" @click="showMemberDetail(item)">
                {{ $t('log_account.header4') }}
              </v-btn>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
      <account-detail v-if="showDetail" :dialog="showDetail" :detail="currentMemberDetail"
        @setDialog="showDetail = $event" />
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import AccountDetail from '@/components/logs/AccountDetail.vue'
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
    AccountDetail,
  },

  data() {
    return {
      menu: false,
      showDetail: false,
      toggle_exclusive: 0,
      dateFrom: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      date: null,
      currentMemberDetail: {},
      searchData: null,
      headers: [
        {
          text: '#',
          value: 'id',
          width: '100px',
          sortable: false,
        },
        {
          text: this.$t('log_account.header1'),
          value: 'new_member.phone',
          width: '100px',
          sortable: false,
        },

        {
          text: this.$t('log_account.header2'),
          value: 'admin.name',
          sortable: false,
        },
        {
          text: this.$t('log_account.header3'),
          value: 'created_at',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('log_account.header4'),
          value: 'action',
          align: 'center',
          sortable: false,
        },
      ],
      members: [],
      search: '',
      loading: false,
      dummy: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
    }
  },
  async created() {
    this.addLogPage()
    // const result = await this.getLogEditMember(`page=1&row=${this.pagination.rowsPerPage}`)
    // this.members = result.data
    // this.pagination.totalItems = result.count
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        // this.loading = true
        // let result = []
        // result = this.searchData ? 
        //   await this.getLogEditMember(`page=${newValue.page}&rows=${newValue.itemsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}&search=${this.searchData}`) : 
        //   await this.getLogEditMember(`page=${newValue.page}&rows=${newValue.itemsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}`)
        // this.members = result.data
        // this.pagination.totalItems = result.count
        // this.loading = false
        if (cont && !this.dummy) {
          this.loading = true
          let result = []
          result = this.searchData ?
            await this.getLogEditMember(`page=${newValue.page}&rows=${newValue.itemsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}&search=${this.searchData}`) :
            await this.getLogEditMember(`page=${newValue.page}&rows=${newValue.itemsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}`)
          this.members = result.data
          this.pagination.totalItems = result.count
          this.loading = false
        }
        if (cont && this.dummy) {
          this.loading = true
          let result = []
          result = this.searchData ?
            await this.getLogEditMember(`page=${newValue.page}&rows=${newValue.itemsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}&search=${this.searchData}`) :
            await this.getLogEditMember(`page=${newValue.page}&rows=${newValue.itemsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}`)
          this.members = result.data
          this.pagination.totalItems = result.count
          this.loading = false
        }
      },
      deep: true,
    },
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch("https://jsonip.com/").then(res => res.json().then(el => (
          data = {
            ip: el.ip,
            name: 'Logs สมาชิก',
            url: window.location.href,
            detail: 'รายการ Logs สมาชิก',
            admin: userSystem.name ? userSystem.name : '',
          }
        )))
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getLogEditMember(params) {
      try {
        return this.$store.dispatch('getLogEditMember', params)
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
    },
    async findByDate() {
      this.loading = true
      this.dummy = false;
      const params = this.searchData ?
        `page=1&rows=${this.pagination.rowsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}&search=${this.searchData}` :
        `page=1&rows=${this.pagination.rowsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}`
      const result = await this.getLogEditMember(params)
      this.pagination.totalItems = result.count
      this.logs = result.data
      this.pagination.page = 1
      this.loading = false
    },
    showMemberDetail(value) {
      this.currentMemberDetail = value
      this.showDetail = true
    },
    async searchKeyword(value) {
      this.dummy = true;
      this.searchData = value
      this.loading = true
      let result = {}
      if (value) {
        result = await this.getLogEditMember(`page=1&rows=${this.pagination.rowsPerPage}&search=${value}`)
      } else {
        result = await this.getLogEditMember(`page=1&rows=${this.pagination.rowsPerPage}`)
      }
      this.pagination.totalItems = result.count
      this.logs = result.data
      this.members = result.data
      this.loading = false
      this.pagination.page = 1
    },
    formatDate(date) {
      return moment(date).format('YY-MM-DD HH:mm')
    },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },
  },
}
</script>
